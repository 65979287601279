import { LinearProgress, linearProgressClasses, styled } from "@mui/material";

type BorderLinearProgressProps = {
    borderRadius?: number;
    height?: number;
    primaryColor?: string;
    secondaryColor?: string;
};

const BorderLinearProgress = styled(LinearProgress)<BorderLinearProgressProps>(
    ({
        borderRadius = 5,
        height = 10,
        primaryColor = "#1a90ff",
        secondaryColor,
        theme,
    }) => ({
        [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: primaryColor,
            borderRadius: borderRadius,
            ...theme.applyStyles("dark", {
                backgroundColor: secondaryColor ?? "#308fe8",
            }),
        },
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[200],
            ...theme.applyStyles("dark", {
                backgroundColor: theme.palette.grey[800],
            }),
        },
        borderRadius: borderRadius,
        height: height,
    }),
);

export default BorderLinearProgress;
