import { List, ListItemButton, styled } from "@mui/material";

export function SubNavigationCategoryList({
    items,
    setActiveMenu,
    setMenuContent,
}: {
    items: { active?: boolean; label: string }[];
    setActiveMenu: React.Dispatch<React.SetStateAction<null | string>>;
    setMenuContent: (category: string) => void;
}) {
    const ActiveListItemButton = styled(ListItemButton)(({ theme }) => ({
        "&:hover": {
            background: theme.palette.primary.main,
        },
        background: theme.palette.primary.main,
        borderRadius: "10px",
        color: "#fff",
    }));

    return (
        <List
            sx={{
                fontSize: "14px",
                fontWeight: "bold",
                overflow: "hidden",
                padding: "1em",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            }}
        >
            {items.map((item) =>
                item.active ? (
                    <ActiveListItemButton
                        key={item.label}
                        onClick={() => {
                            setActiveMenu(item.label);
                            setMenuContent(item.label);
                        }}
                        sx={{ paddingY: "1em" }} // weird hack to make padding work
                    >
                        {item.label}
                    </ActiveListItemButton>
                ) : (
                    <ListItemButton
                        key={item.label}
                        onClick={() => {
                            setActiveMenu(item.label);
                            setMenuContent(item.label);
                        }}
                        sx={{ borderRadius: "10px", paddingY: "1em" }}
                    >
                        {item.label}
                    </ListItemButton>
                ),
            )}
        </List>
    );
}
