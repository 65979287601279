import * as icons from "./icons";

type iconEntry = [
    string,
    React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string;
        }
    >,
];

export function Icon({
    color,
    name,
    size,
}: {
    color?: string;
    name: keyof typeof icons;
    size?: "lg" | "md" | "sm" | "xl" | "xs" | number;
}) {
    let pixelSize: number;
    if (typeof size === "number") {
        pixelSize = size;
    } else {
        switch (size) {
            case "lg": {
                pixelSize = 48;
                break;
            }
            case "md": {
                pixelSize = 32;
                break;
            }
            case "sm": {
                pixelSize = 24;
                break;
            }
            case "xl": {
                pixelSize = 64;
                break;
            }
            case "xs": {
                pixelSize = 16;
                break;
            }
            default: {
                pixelSize = 32;
                break;
            }
        }
    }

    const iconEntry = Object.entries(icons).find(
        ([key]) => key === name,
    ) as iconEntry;
    return iconEntry[1]({
        color: color,
        height: pixelSize,
        width: pixelSize,
    });
}
