import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Grid2,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from "@mui/material";
import { Gauge } from "@mui/x-charts";
import { Icon, IconName } from "@theme/index";
import { useTranslation } from "react-i18next";

import BorderLinearProgress from "../general/linear-progress-bar";

type AutomationFunction = WidgetListItem & { label?: string };

type Task = WidgetListItem & { count?: number };

type WidgetListItem = {
    caption: string;
    icon: IconName;
    id: number;
};

export default function AdministrationStatusWidget() {
    const { t } = useTranslation("widgets");
    const automationFunctions: AutomationFunction[] = [
        {
            caption: "Automatisch aanmaningen versturen",
            icon: "AccesibilityLocked",
            id: 0,
            label: "Instellen",
        },
        {
            caption: "Bankrekening(en) koppelen",
            icon: "CloseX",
            id: 1,
            label: "Status bekijken",
        },
        {
            caption: "Scan & Herken Premium",
            icon: "Summarize",
            id: 2,
        },
    ];

    const tasks: Task[] = [
        {
            caption: "Doe je OB-aangifte",
            icon: "AccesibilityLocked",
            id: 0,
        },
        {
            caption: "Werk je postbus bij",
            count: 4,
            icon: "File",
            id: 1,
        },
        {
            caption: "Betaal je inkoopfacturen",
            count: 1,
            icon: "ClipboardLab",
            id: 2,
        },
    ];

    const data = {
        automationFunctions: automationFunctions,
        automationProgress: 2,
        tasks: tasks,
    };

    return (
        <Card
            elevation={3}
            sx={(theme) => ({
                backgroundColor:
                    theme.palette.mode === "dark"
                        ? theme.palette.background.paper
                        : theme.palette.background.default,
            })}
            variant="elevation"
        >
            <CardContent>
                <Stack>
                    <Stack direction="row" spacing={2} useFlexGap>
                        <Typography
                            sx={{ fontFamily: "Ubuntu" }}
                            variant="subtitle1"
                        >
                            {t("AdministrationStatus.statusOf")}{" "}
                            Administratienaam
                        </Typography>
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{ marginRight: "auto" }}
                        >
                            <Icon name="ImportCompany" size="sm" />
                            <em>{t("AdministrationStatus.premium")}</em>
                        </Stack>
                        <Button color="info" sx={{ color: "#252626" }}>
                            <Stack direction="row" spacing={1}>
                                <Icon name="LaptopGraph" size="sm" />
                                <Typography
                                    sx={{ fontFamily: "Ubuntu" }}
                                    variant="subtitle1"
                                >
                                    {t("AdministrationStatus.startpage")}
                                </Typography>
                                <Icon name="ChevronRight" size="sm" />
                            </Stack>
                        </Button>
                    </Stack>
                    <Grid2
                        container
                        spacing={2}
                        sx={{ height: "100%", marginTop: 1 }}
                    >
                        <Grid2 size={6}>
                            <Stack
                                alignItems="center"
                                direction="row"
                                spacing={2}
                                sx={{ height: "125px" }}
                            >
                                <Gauge value={60} />
                                <Stack minWidth="300px">
                                    <strong>
                                        {t("AdministrationStatus.automate")}
                                    </strong>
                                    <Typography
                                        sx={{ fontFamily: "Ubuntu" }}
                                        variant="subtitle1"
                                    >
                                        {t(
                                            "AdministrationStatus.automateMessage",
                                        )}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <List
                                dense
                                disablePadding
                                sx={{
                                    "& .MuiListItemIcon-root": {
                                        marginRight: 1,
                                        minWidth: "25px",
                                    },
                                    minWidth: "440px",
                                }}
                            >
                                {data.automationFunctions.map(
                                    (automationFunction) => (
                                        <AutomationFunction
                                            caption={automationFunction.caption}
                                            icon={automationFunction.icon}
                                            id={automationFunction.id}
                                            key={automationFunction.id}
                                            label={automationFunction.label}
                                        />
                                    ),
                                )}
                            </List>
                        </Grid2>
                        <Grid2 size={6}>
                            <Stack gap={1}>
                                <Typography
                                    sx={{ fontFamily: "Ubuntu" }}
                                    variant="subtitle2"
                                >
                                    {t("AdministrationStatus.update")}
                                </Typography>
                                <Box
                                    sx={{
                                        alignItems: "center",
                                        display: "flex",
                                    }}
                                >
                                    <Box sx={{ mr: 1, width: "100%" }}>
                                        <BorderLinearProgress
                                            value={30}
                                            variant="determinate"
                                        />
                                    </Box>
                                    <Box sx={{ minWidth: 35 }}>
                                        <Typography
                                            sx={{ color: "text.secondary" }}
                                            variant="body2"
                                        >{`${Math.round(30)}%`}</Typography>
                                    </Box>
                                </Box>
                                <List
                                    dense
                                    disablePadding
                                    sx={{
                                        "& .MuiListItemIcon-root": {
                                            marginRight: 1,
                                            minWidth: "25px",
                                        },
                                    }}
                                >
                                    {data.tasks.map((task) => (
                                        <Task
                                            caption={task.caption}
                                            count={task.count}
                                            icon={task.icon}
                                            id={task.id}
                                            key={task.id}
                                        />
                                    ))}
                                </List>
                            </Stack>
                        </Grid2>
                    </Grid2>
                </Stack>
            </CardContent>
        </Card>
    );
}

function AutomationFunction({ caption, icon, label }: AutomationFunction) {
    return (
        <ListItem
            disablePadding
            secondaryAction={label && <ListItemButton>{label}</ListItemButton>}
        >
            <ListItemIcon>
                <Icon name={icon} size="sm" />
            </ListItemIcon>
            <ListItemText primary={caption} />
        </ListItem>
    );
}

function Task({ caption, count, icon }: Task) {
    return (
        <ListItem
            disablePadding
            secondaryAction={<Icon name="ChevronRight" size="sm" />}
            sx={{ "& .MuiListItemSecondaryAction-root": { top: "60%" } }}
        >
            <ListItemIcon>
                <Icon name={icon} size="sm" />
            </ListItemIcon>
            <ListItemButton sx={{ paddingLeft: 0 }}>{caption}</ListItemButton>
            {count && (
                <ListItemAvatar sx={{ paddingRight: "3em" }}>
                    <Chip label={count} size="small" />
                </ListItemAvatar>
            )}
        </ListItem>
    );
}
