import { Button, Menu } from "@mui/material";
import { Icon, IconName } from "@theme/index";
import {
    bindMenu,
    bindTrigger,
    usePopupState,
    Variant,
} from "material-ui-popup-state/hooks";
import React from "react";

export default function MenuButton({
    children,
    icon,
    label,
    variant,
}: {
    children: React.ReactNode;
    icon: IconName;
    label: string;
    variant: Variant;
}) {
    const popupState = usePopupState({
        popupId: `menu-button-${label}`,
        variant: variant,
    });
    return (
        <div>
            <Button
                color="inherit"
                {...bindTrigger(popupState)}
                sx={{ color: "white" }}
            >
                <Icon color="white" name={icon} size="md" />
            </Button>
            <Menu {...bindMenu(popupState)}>{children}</Menu>
        </div>
    );
}
