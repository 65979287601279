import CompanyStatusWidget from "@/components/widgets/company-status-widget";
import { Box, Button, Grid2 } from "@mui/material";
import { useAtom } from "jotai";

import { companyIdAtom } from "../atoms";

export function CompanyPage() {
    const [companyId, setCompanyId] = useAtom(companyIdAtom);
    return (
        <Grid2 container>
            <Box sx={{ margin: 5 }}>
                <CompanyStatusWidget />
            </Box>

            <Grid2>
                <p>
                    {companyId ? (
                        <>Company ID: {companyId}</>
                    ) : (
                        <>No company ID</>
                    )}
                </p>
                <Button onClick={() => setCompanyId("")} variant="contained">
                    reset company ID
                </Button>
            </Grid2>
        </Grid2>
    );
}
