import { lightNeutral } from "@/theme/colors";
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardContent,
    Divider,
    Grid2,
    Stack,
    Typography,
} from "@mui/material";
import { Icon } from "@theme/icon";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function AdministrationPickerWidget() {
    const { t } = useTranslation("widgets");
    type Administration = {
        caption: string;
        id: number;
        name: string;
    };

    const demoData: Administration[] = [
        { caption: "Bedrijfsomgeving", id: 0, name: "Administration 1" },
        { caption: "Bedrijfsomgeving", id: 1, name: "Administration 2" },
        { caption: "Bedrijfsomgeving", id: 2, name: "Administration 3" },
        { caption: "Bedrijfsomgeving", id: 3, name: "Administration 4" },
        { caption: "Bedrijfsomgeving", id: 4, name: "Administration 5" },
        { caption: "Bedrijfsomgeving", id: 5, name: "Administration 6" },
    ];

    const [data, setData] = useState<Administration[]>([]);

    const [viewType, setViewType] = useState<"favorite" | "recent">("favorite");

    return (
        <Card
            elevation={3}
            sx={(theme) => ({
                backgroundColor:
                    theme.palette.mode === "dark"
                        ? theme.palette.background.paper
                        : theme.palette.background.default,
            })}
            variant="elevation"
        >
            <CardContent>
                <Stack>
                    <Stack direction="row" spacing={2}>
                        <Typography
                            sx={{ fontFamily: "Ubuntu" }}
                            variant="subtitle1"
                        >
                            {t("AdministrationPicker.quickMessage")}
                        </Typography>
                        <Box
                            sx={{
                                alignItems: "center",
                                borderRadius: "8px",
                                display: "flex",
                                gap: "8px",
                                height: "32px",
                                padding: "4px",
                            }}
                        >
                            <ButtonGroup>
                                <Button
                                    onClick={() => setViewType("favorite")}
                                    sx={{
                                        border: "none",
                                        borderRadius: "8px",
                                    }}
                                >
                                    {t("AdministrationPicker.favorite")}
                                </Button>
                                <Button
                                    onClick={() => setViewType("recent")}
                                    sx={{
                                        border: "none",
                                        borderRadius: "8px",
                                    }}
                                >
                                    {t("AdministrationPicker.recent")}
                                </Button>
                            </ButtonGroup>
                        </Box>
                        <Divider flexItem orientation="vertical" />
                        <Button
                            color="secondary"
                            onClick={() => setData(demoData)}
                            startIcon={<Icon name="Add" size="sm" />}
                        >
                            {t("AdministrationPicker.new")}
                        </Button>
                        <Button
                            color="secondary"
                            onClick={() => setData([])}
                            startIcon={<Icon name="ListView" size="sm" />}
                        >
                            {t("AdministrationPicker.overview")}
                        </Button>
                    </Stack>
                    <Grid2 container spacing={2} sx={{ marginTop: 1 }}>
                        {data.length === 0 && viewType === "favorite" && (
                            <NoFavoritesMessage />
                        )}
                        {data.length === 0 && viewType === "recent" && (
                            <EmptyMessage />
                        )}
                        {data.map((administration) => {
                            return (
                                <Grid2 key={administration.id} size={4}>
                                    <AdministrationCard
                                        caption={administration.caption}
                                    >
                                        {administration.name}
                                    </AdministrationCard>
                                </Grid2>
                            );
                        })}
                    </Grid2>
                </Stack>
            </CardContent>
        </Card>
    );
}

function AdministrationCard({
    caption,
    children,
}: {
    caption: string;
    children?: React.ReactNode;
}) {
    return (
        <Card
            elevation={1}
            sx={{
                cursor: "pointer",
                padding: 2,
            }}
            variant="elevation"
        >
            <CardContent
                sx={[
                    (theme) => ({
                        background: "url(/assets/cash-register.svg) no-repeat",
                        backgroundColor: lightNeutral[150],
                        ...theme.applyStyles("dark", {
                            backgroundBlendMode: "soft-light",
                            backgroundColor: "#212426",
                        }),
                    }),
                ]}
            >
                <Grid2 container>
                    <Grid2 size={10}>
                        <Typography sx={{ paddingBottom: 0 }} variant="h6">
                            {children}
                        </Typography>
                        <Typography variant="caption">{caption}</Typography>
                        <Stack
                            direction="row"
                            gap={1}
                            sx={{ paddingTop: "1em" }}
                        >
                            <Icon color="#6B6B6B" name="Library" size="sm" />
                            <Icon color="#6B6B6B" name="Info" size="sm" />
                        </Stack>
                    </Grid2>
                    <Grid2 alignContent="center" size={2}>
                        <Button color="primary" variant="contained">
                            <Icon name="ChevronRight" />
                        </Button>
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    );
}

function EmptyMessage() {
    const { t } = useTranslation("widgets");
    return (
        <Grid2
            alignItems="center"
            display="flex"
            justifyContent="center"
            size={12}
        >
            <Stack
                gap={2}
                sx={{
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <img alt="Error Robot" src="/assets/error-robot.svg" />
                <Typography variant="h6">
                    {t("AdministrationPicker.emptyMessage")}
                </Typography>
                <Typography variant="body1">
                    {t("AdministrationPicker.emptyCallToAction")}
                </Typography>
                <Button
                    color="primary"
                    startIcon={<Icon name="Plus" size="sm" />}
                    variant="contained"
                >
                    {t("AdministrationPicker.new")}
                </Button>
            </Stack>
        </Grid2>
    );
}

function NoFavoritesMessage() {
    const { t } = useTranslation("widgets");
    return (
        <Grid2
            alignItems="center"
            display="flex"
            justifyContent="center"
            size={12}
        >
            <Stack
                gap={2}
                sx={{
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Grid2
                    container
                    display="flex"
                    justifyContent="right"
                    paddingX={2}
                >
                    <Grid2 display="flex" justifyContent="right" size={6}>
                        <img alt="Favorites" src="/assets/favorites-star.svg" />
                    </Grid2>
                    <Grid2 display="flex" justifyContent="right" size={6}>
                        <img
                            alt="Arrow pointing up"
                            src="/assets/upwards-arrow.svg"
                        />
                    </Grid2>
                </Grid2>

                <Typography variant="h6">
                    {t("AdministrationPicker.NoFavoriteMessage")}
                </Typography>
                <Typography sx={{ maxWidth: "80%" }} variant="body1">
                    {t("AdministrationPicker.NoFavoriteCallToAction")}
                </Typography>
            </Stack>
        </Grid2>
    );
}
