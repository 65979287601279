import { createRoute } from "@tanstack/react-router";

import { CompanyOverviewPage } from "../pages/company-overview-page";
import rootRoute from "./__root";

const companyOverviewRoute = createRoute({
    component: CompanyOverviewPage,
    getParentRoute: () => rootRoute,
    path: "/company/overview",
});

export default companyOverviewRoute;
