import { tooltipClasses, TooltipProps } from "@mui/material";
import { Tooltip as MUITooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <MUITooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        "&:before": {
            borderColor: theme.palette.info.main,
            borderStyle: "solid",
            borderWidth: 1,
        },
        color: theme.palette.background.default,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.background.default,
        borderColor: theme.palette.info.main,
        borderStyle: "solid",
        borderWidth: 1,
        color: theme.palette.text.secondary,
        fontSize: 14,
        maxWidth: 210,
        padding: theme.spacing(2),
    },
}));

export default function Tooltip(props: TooltipProps) {
    return <StyledTooltip {...props} />;
}
