import {
    Button,
    Card,
    CardContent,
    CardHeader,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from "@mui/material";
import { Icon } from "@theme/icon";
import { useTranslation } from "react-i18next";

export default function WelcomeWidget() {
    const { t } = useTranslation("widgets");
    const dummyListItems = [
        { id: 1, primary: "Maak je eerste administratie aan" },
        { id: 2, primary: "Je hebt 5 openstaande Taken" },
        { id: 3, primary: "Je hebt 5 ongelezen Berichten" },
        { id: 4, primary: "Koppel je eerste bankrekening" },
        { id: 5, primary: "Je hebt 5 openstaande Facturen" },
    ];
    return (
        <Card
            elevation={3}
            sx={(theme) => ({
                backgroundColor: "#ECF1F9", //this removes a inexplicable white background
                backgroundImage:
                    theme.palette.mode === "dark"
                        ? "url(/assets/widget-bg-dark.svg)"
                        : "url(/assets/widget-bg.svg)",
                backgroundPosition: "top",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            })}
            variant="elevation"
        >
            <CardHeader
                title={t("Welcome.welcomeBack")}
                titleTypographyProps={{
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                    margin: "0.1em",
                    mx: "32px",
                    variant: "h4",
                }}
            />
            <CardContent>
                <nav aria-label="welcome tasks">
                    <List
                        dense
                        sx={{
                            maxHeight: "14em",
                            overflowY: "auto",
                            paddingTop: "0",
                        }}
                    >
                        {dummyListItems.map((item) => (
                            <WidgetListItem
                                key={item.id}
                                primary={item.primary}
                            />
                        ))}
                    </List>
                </nav>
            </CardContent>
        </Card>
    );
}

function WidgetListItem({ primary }: { primary: string }) {
    return (
        <ListItem>
            <ListItemButton
                sx={{
                    borderBottom: "1px dashed #E0E0E0",
                    paddingBottom: "0.5em",
                    paddingRight: 0,
                }}
            >
                <ListItemText primary={primary} sx={{ marginRight: "7em" }} />
                <Button size="small" variant="contained">
                    <Icon name="ChevronRight" size="sm" />
                </Button>
            </ListItemButton>
        </ListItem>
    );
}
